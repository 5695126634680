body{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}




/* Navbar */

.nav-shadow {
  box-shadow: 0 0 50px 0 rgb(102, 58, 189);
  background-color: rgb(6, 8, 10);
}

.tog-col {
  background-color: rgb(102, 58, 189);
}

.mail {
  transition: 0.5s ease-out;
}

.mail:hover {
  color: rgb(100, 61, 184) !important;
}

.dropdown-anim {
  position: relative;
  display: inline-block;
}

.drop-anim {
  background-color: rgb(6, 8, 10);
  box-shadow: 0 0 15px 0 rgb(102, 58, 189);
}

.dropdown-anim::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(102, 58, 189);
  transition: width 0.3s ease-in-out;
}

.dropdown-anim:hover::after {
  width: 100%;
}

.hireme {
  background-color: rgb(102, 58, 189);
  color: white;
  padding: 10px 20px;
  font-size: 1rem; 
  display: inline-block;
}

.hireme:hover {
  border: 1px rgb(102, 58, 189) solid;
  background-color: transparent;
  color: rgb(102, 58, 189);
}


.btn {
  transition: all 0.3s ease-in-out;
}

@media (max-width: 768px) {
  .nav-shadow {
    box-shadow: 0 0 20px 0 rgb(102, 58, 189);
  }

  .hireme {
    font-size: 0.9rem; 
    padding: 8px 16px; 
  }

  .dropdown-anim::after {
    height: 1px; 
  }
}

@media (max-width: 480px) {
  .hireme {
    font-size: 0.8rem; 
    padding: 6px 12px; 
  }

  .mail:hover {
    color: rgb(150, 90, 220) !important; 
  }
  .dropdown-anim:hover::after {
    width: 80%;
  }
}



/* About */


.about {
  background-color: rgb(14, 7, 20);
  height: auto;
}

.about-intro {
  padding: 50px 0 100px 0;
}
.about .intro1 {
  font-size: 6vh;
  color: rgb(222, 222, 222);
  width: 18ch;
  animation-delay: 0s;
}

.about .intro2 {
  font-size: 8vh;
  color: rgb(143, 94, 247);
  width: 16ch;
  animation-delay: 3s;
}

@keyframes typing {
  from { width: 0; }
  to { width: 100%; }
}

@keyframes blink-caret {
  from, to { border-color: transparent; }
  50% { border-color: rgb(143, 94, 247); }
}

.typing-animation {
  overflow: hidden;
  border-right: 0.15em solid rgb(143, 94, 247);
  white-space: nowrap;
  width: 0;
  animation: typing 3s steps(40, end), blink-caret 0.75s step-end infinite;
}
@media (max-width: 1024px) {
  .about .intro1 {
    font-size: 8vh;
  }
  
  .about .intro2 {
    font-size: 9vh;
  }
}

@media (max-width: 768px) {
  .about .intro1 {
    font-size: 6vh;
  }
  
  .about .intro2 {
    font-size: 7vh;
  }
}

@media (max-width: 480px) {
  .about .intro1 {
    font-size: 5vh;
  }
  
  .about .intro2 {
    font-size: 6vh !important;
  }
}
@media (max-width: 300px) {
  .about .intro1 {
    font-size: 4vh;
  }
  
  .about .intro2 {
    font-size: 5vh;
  }
}

.cv-btn {
  background: transparent;
  border: none;
  border: solid 1px rgb(102, 58, 189);
  color: rgb(102, 58, 189);
  letter-spacing: 3px;
  transition: 0.5s ease-out;
}

.cv-btn:hover {
  background-color: rgb(102, 58, 189);
  color: white;
}



.img-box {
  height: 70vh;
  width: 70vh !important;
  margin-top: 40px;
}

.img-box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.border-col {
  border: solid 1px white;
  transform: rotate(10deg);
  transition: 0.3s ease-out;
}

.border-col:hover {
  border: solid 1px rgb(102, 58, 189);
  transform: rotate(0deg);
}

.social-icon {
  color: rgb(102, 58, 189);
  border: solid 1px rgb(102, 58, 189);
  transition: 0.5s ease-out;
}

.social-icon:hover {
  color: white;
  background-color: rgb(102, 58, 189);
}

.experience {
  font-size: 10vh !important;
}

@media (max-width: 992px) {
  .img-box {
    height: 50vh;
    width: 50vh !important;
  }

  .experience {
    font-size: 8vh !important;
  }
  .exp{
    font-size: 4vh;
  }
}

@media (max-width: 768px) {
  .img-box {
    height: 60vh;
    width: 60vh !important;
  }

  .experience {
    font-size: 7vh !important;
  }

  .experience-content {
    text-align: center;
  }
  .exp{
    font-size: 3vh;
  }
}

@media (max-width: 576px) {
  .img-box {
    height: 50vh;
    width: 50vh !important;
  }

  .experience {
    font-size: 3vh !important;
  }
  .exp{
    font-size: 2vh;
  }

  .experience-content {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media (max-width: 400px) {
  .img-box {
    height: 40vh;
    width: 40vh !important;
  }}


/* Services */
.services {
  background-color: rgb(6, 8, 10);
}

.ser-head {
  font-size: 7vh !important;
  color: rgb(143, 94, 247) !important;
}

.ser-intro {
  padding: 100px 0 50px 0;
}

.service-section {
  padding: 0 0 100px 0;
}

.service-item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  background-color: rgb(43, 20, 84);
  border-radius: 15px;
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  height: 100%;
  width: auto !important;
  color: white;
  background: transparent;
  border: solid 1px rgb(103, 59, 191);
}

.service-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  background-color: rgb(103, 59, 191);
  border: transparent;
}

.service-title {
  font-weight: bold;
  margin: 20px 0;
  font-size: 5vh;
  color: #fff;
}

.description {
  color: #aaa;
  font-size: 3vh;
  margin: 0;
}

.arrow {
  font-size: 3vh;
  color: rgb(103, 59, 191);
  transform: rotate(-45deg);
  transition: 0.5s ease-out;
}

.service-item:hover .arrow {
  color: white;
  transform: rotate(45deg);
}

.service-number {
  font-size: 4vh;
  font-weight: bold;
  color: rgb(103, 59, 191);
}

.service-item:hover .service-number {
  color: white;
}

/* Responsive design */
@media (max-width: 1200px) {
  .ser-head {
    font-size: 6vh;
  }

  .ser-intro {
    padding: 80px 0 40px 0;
  }

  .service-title {
    font-size: 4vh;
  }

  .description {
    font-size: 2.5vh;
  }

  .arrow {
    font-size: 2.5vh;
  }
}

@media (max-width: 992px) {
  .ser-head {
    font-size: 5.5vh;
  }

  .ser-intro {
    padding: 70px 0 35px 0;
  }

  .service-title {
    font-size: 4vh;
  }

  .description {
    font-size: 2vh;
  }

  .arrow {
    font-size: 2vh;
  }
}

@media (max-width: 768px) {
  .ser-head {
    font-size: 5vh;
  }

  .ser-intro {
    padding: 60px 0 30px 0;
  }

  .service-title {
    font-size: 3.5vh;
  }

  .description {
    font-size: 1.8vh;
  }

  .arrow {
    font-size: 1.8vh;
  }

  .service-item {
    padding: 30px;
  }
}

@media (max-width: 576px) {
  .ser-head {
    font-size: 4.5vh;
  }

  .ser-intro {
    padding: 50px 0 25px 0;
  }

  .service-title {
    font-size: 3vh;
  }

  .description {
    font-size: 1.6vh;
  }

  .arrow {
    font-size: 1.6vh;
  }

  .service-item {
    padding: 20px;
  }
}


/* Projects */
.projects {
  background-color: rgb(14, 7, 20);
}

.proj-head {
  font-size: 7vh !important;
  color: rgb(143, 94, 247) !important;
}

.proj-intro {
  padding: 100px 0 50px 0;
}

.proj-section {
  padding: 0 0 100px 0;
}

.proj-filter-container {
  justify-content: center;
  display: flex;
}

.proj-box {
  height: 80vh !important;
  width: 90vh !important;
  border-radius: 10px;
  background-color: rgb(20, 12, 28);
  padding: 20px 0 0 0;
  margin: 40px auto 0 auto;
}

.proj-img {
  height: 100% !important;
  width: 100% !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  position: relative;
}

.proj-img img {
  height: 98% !important;
  width: 100% !important;
  transition: transform 1s ease;
  position: relative;
  object-fit: fill;
}


.proj-content {
  position: absolute;
  width: 95%;
  background-color: rgb(99, 57, 184);
  top: 85%;
  left: 50%;
  height: auto;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  align-items: center;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  opacity: 0;
  transition: all 0.5s ease;
}

.proj-content .h3 {
  color: rgb(20, 12, 28) !important;
}

.proj-box:hover .proj-content {
  top: 70%;
  opacity: 1;
}

.proj-content .arrow {
  color: white;
  font-size: 6vh;
}
.anim-col{
  background-color: rgb(99, 57, 184);
}

.proj-box:hover .arrow {
  transform: rotate(315deg);
  transition: 300ms;
}

@media (max-width: 992px) {
  .proj-box {
    height: 60vh !important;
    width: 70vh !important;
  }
}

@media (max-width: 768px) {
  .proj-box {
    height: 50vh !important;
    height: 60vh !important;
    margin-bottom: 20px;
  }

  .proj-content {
    padding: 10px;
    flex-direction: column; 
  }

  .proj-content .h3 {
    font-size: 3vh;
  }

  .proj-content .arrow {
    font-size: 4vh; 
  }
}

@media (max-width: 576px) {
  .proj-head {
    font-size: 5vh !important;
  }

  .proj-intro {
    padding: 50px 0 25px 0;
  }

  .proj-box {
    height: 40vh !important;
    width: 50vh !important;
  }

  .proj-content .h3 {
    font-size: 2.5vh; 
  }

  .proj-content .arrow {
    font-size: 3vh;
  }
}


/* Experience */


.experiences{
  background-color: rgb(6, 8, 10);
  padding: 100px 0 100px 0;
}

.ex-box{
  background-color: rgb(20, 12, 28);
  border-radius: 20px;
  padding: 20px 0 20px 30px;
  transition: 1s ease;
  height: auto;
}
.ex-box .h3{
  color: rgb(158, 112, 250);
  transition: 1s ease;
}
.ex-box:hover .h3{
  color: white;
}

.ex-head i{
  color: rgb(158, 112, 250);
}
.experiences .h4{
  color: rgb(135, 79, 247);
  transition: 1s ease;
}
.ex-box:hover{
  background-color: rgb(135, 79, 247);
}
.ex-box:hover .h4{
  color: white;
}



/* Skills */


.skills{
  background-color: rgb(14, 7, 20);
  padding: 100px 0 100px 0;
}

.skill-head{
  font-size: 7vh !important;
  color: rgb(143, 94, 247) !important;
}
.skill-intro{
  padding: 100px 0 50px 0;
}
.skill-content{
  background-color: rgb(20, 12, 28);
  transition: all 1s ease;
  width: 160px;
  height: auto;
  
}

.skill-box p{
  font-size: 4vh;
  text-align: center;
}
.skill-content:hover{
  border: solid 1px rgb(110, 64, 201);
  background-color: rgb(43, 20, 84);
}
.skill-content:hover p{
  color: rgb(110, 64, 201);
}
.skill-content div{
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  margin: auto;
}
.skill-box img{
  width: 70%;
  filter: grayscale(90);
  object-fit: contain;
  transition: all 1s ease;
}
.skill-content:hover img{
  width: 100%;
  filter: grayscale(0);
}
.skill-box .h6{
  color: rgb(118, 79, 247);
  font-size: 20px;
}




/* Testemonial */


.reviews{
  background-color: rgb(6, 8, 10);
  padding: 100px 0 100px 0;
}

.reviews .h1{
  color: rgb(145, 94, 247);
  font-size: 50px;
}

.mySwiper {
  width: 100%;
  height: 100%;
}

.swiperSlide {
  font-size: 18px;
  background: rgb(20, 12, 28);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px !important;
  height: auto !important;
  width: 300px !important;
}
.testimg{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.testimg2{
  width: 80px !important;
}
.testimg1{
  height: 30px !important;
  width: 30px !important;
  object-fit: cover;
}
.testimg2{
  border-radius: 5px 5px 5px 125px;
}

.swiperSlide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiperSlide i{
  color: rgb(135, 79, 247);
}
@media (max-width: 992px) {
  .reviews {
      padding: 80px 0;
  }

  .reviews .h1 {
      font-size: 40px;
  }

  .reviews .h5 {
      font-size: 20px;
  }

  .swiperSlide {
      width: 250px !important;
  }

  .testimg1,
  .testimg2 {
      width: 60px !important;
  }

  .swiperSlide .h6 {
      font-size: 14px;
  }

  .swiperSlide p {
      font-size: 14px;
  }
}

@media (max-width: 768px) {


  .cv-btn{
    font-size: 2vh;
  }
  .reviews {
      padding: 60px 0;
  }

  .reviews .h1 {
      font-size: 35px;
  }

  .reviews .h5 {
      font-size: 18px;
  }

  .swiperSlide {
      width: 220px !important;
  }

  .testimg1,
  .testimg2 {
      width: 50px !important;
  }

  .swiperSlide .h6 {
      font-size: 13px;
  }

  .swiperSlide p {
      font-size: 13px;
  }
}

@media (max-width: 576px) {
  .cv-btn{
    font-size: 1.5vh;
  }
  .reviews {
      padding: 50px 0;
  }

  .reviews .h1 {
      font-size: 28px;
  }

  .reviews .h5 {
      font-size: 16px;
  }

  .swiperSlide {
      width: 180px !important;
  }

  .testimg1,
  .testimg2 {
      width: 40px !important;
  }

  .swiperSlide .h6 {
      font-size: 12px;
  }

  .swiperSlide p {
      font-size: 12px;
  }
}




/* Blogs */
.blogs {
  background-color: rgb(14, 7, 20);
}

.blog-head {
  font-size: 7vh !important;
  color: rgb(143, 94, 247) !important;
}

.blog-intro {
  padding: 100px 0 50px 0;
}

.blog-section {
  padding: 0 0 100px 0;
}

.blog-box {
  background-color: rgb(20, 12, 28);
  cursor: pointer;
  position: relative;
  padding: 0;
  border-radius: 20px;
  overflow: hidden;
  transition: all 1s ease;
  margin: 15px;
  width: 24%; 
  height: auto;
}

.blog-image-container {
  height: auto; 
  overflow: hidden;
}

.blog-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: transform 1s ease;
}

.blog-box:hover .blog-image {
  transform: scale(1.2);
}

.blog-content {
  position: absolute;
  height: auto;
  width: 95%;
  background-color: rgb(43, 20, 84);
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.blog-content .h6 {
  color: rgb(135, 79, 232);
  transition: 0.5s ease;
}

.blog-box:hover .h6 {
  color: white;
}

.blog-box .category {
  position: absolute;
  top: 5%;
  left: 5%;
  background-color: rgb(107, 62, 199);
  border-radius: 20px;
  padding: 0 10px;
}


@media (max-width: 1200px) {
  .blog-box {
    width: 24%; 
  }

  .blog-head {
    font-size: 6vh;
  }

  .blog-intro {
    padding: 80px 0 40px 0;
  }


  .blog-intro p.h1.blog-head {
    font-size: 6vh;
  }

  .blog-intro p.h6 {
    font-size: 1.2rem;
  }

  .blog-content .h6 {
    font-size: 0.9rem;
  }

  .blog-content .h1 {
    font-size: 1.5rem;
  }

  .blog-box .category {
    font-size: 0.8rem;
  }
}

@media (max-width: 992px) {
  .blog-box {
    width: 45%; 
  }

  .blog-head {
    font-size: 5.5vh;
  }


  .blog-intro p.h1.blog-head {
    font-size: 5.5vh;
  }

  .blog-intro p.h6 {
    font-size: 1.1rem;
  }

  .blog-content .h6 {
    font-size: 0.85rem;
  }

  .blog-content .h1 {
    font-size: 1.4rem;
  }

  .blog-box .category {
    font-size: 0.75rem;
  }
}
@media (max-width: 600px) {
  .blog-box {
    width: 80%; 
  }
}



/* Footer */



.footer {
  background-color: rgb(14, 7, 20); 
  padding: 30px 0;
  text-align: center !important;
}


.footer-link {
  color: #fff;
  text-decoration: none;
  font-weight: 600;
  margin: 0 15px;
  position: relative;
  display: inline-block;
}


.footer-bottom p{
  color: rgb(135, 60, 181);
}

.footer-bottom a{
  text-decoration: none;
  color: rgb(135, 60, 181);
  font-weight: bold;
}


.footer-link::after {
  content: "";
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: rgb(102, 58, 189);
  transition: 0.3s ease-in-out;
}

.footer-link:hover:after {
  width: 100%;
}



